import s from "./Card.module.scss";
import React from "react";
import { useState } from "react";

import axios from "../axios.js";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Modal from "@mui/material/Modal";

import ShareIcon from "@mui/icons-material/Share";

import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { RWebShare } from "react-web-share";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import CircularProgress from "@mui/material/CircularProgress";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";
import { Check } from "@mui/icons-material";

export const Card = ({ bouquetId, imageUrl, flowers, size, price, isSold }) => {
  const [currentCard, setCurrentCard] = useState("");

  const [contactType, setContactType] = useState("Callback");

  const [orderPhoneNumber, setOrderPhoneNumber] = useState(38);
  const [orderDeliveryNumber, setOrderDeliveryNumber] = useState("");
  const [orderDeliveryAddress, setOrderDeliveryAddress] = useState("");

  const [orderId, setOrderId] = useState(1);
  const [isDelivery, setIsDelivery] = useState(false);
  const [isFull, setIsFull] = useState(false);
  const [checkOrderPost, setCheckOrderPost] = useState(false);
  const [scale, setScale] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [shareLink, setShareLink] = useState("");
  const [priceRequestSucces, setPriceRequestSuccess] = useState(false);
  const [validationError, setValidationError] = useState(false);

  const priceRequest = async () => {
    if (orderPhoneNumber.length === 12) {
      setValidationError(false);
      try {
        const req = await axios.post("/priceRequest", {
          imageUrl,
          orderPhoneNumber,
          flowers: flowers.join(", "),
        });
        if (req.status === 200) {
          setPriceRequestSuccess(true);
          setTimeout(() => {
            setOpenModal(false);
            setPriceRequestSuccess(false);
          }, 4000);
        }
      } catch (error) {
        console.error(error);
        alert(
          `Не вдалося надіслати запит. Будь-ласка, зверніться до адміністратора: ${(
            <a href="tel:+380933800849">+380933800849</a>
          )}`
        );
      }
    } else
      return (
        setValidationError(true),
        alert("Контактний телефон має містити 12 цифр без знака +")
      );
  };

  const orderHandler = async () => {
    const { data } = await axios.get("/orders");
    setOrderId(data + 1);
    setCurrentCard({ imageUrl, flowers, size, price });
    setCheckOrderPost(false);
  };

  const orderConfirm = async () => {
    if (orderPhoneNumber.length === 12) {
      setValidationError(false);
      try {
        setContactType(contactType);
        let contType = contactType;
        let img = currentCard.imageUrl;
        let cost = currentCard.price;

        const order = {
          orderId,
          isDelivery,
          contactType: contType,
          orderPhoneNumber,
          orderDeliveryNumber,
          orderDeliveryAddress,
          imageUrl: img,
          price: cost,
        };

        await axios.post(`/shop/${bouquetId}`, order);

        await axios.patch(`/shop/${bouquetId}`);

        setCheckOrderPost(true);
      } catch (error) {
        console.error(error);
        alert(
          `Не вдалося надіслати замовлення. Будь-ласка, зверніться до адміністратора: ${(
            <a href="tel:+380933800849">+380933800849</a>
          )}`
        );
      }
    } else
      return (
        setValidationError(true),
        alert("Контактний телефон має містити 12 цифр без знака +")
      );
  };

  const cancelHandler = () => {
    setCurrentCard("");
  };
  const cancelShare = () => {
    setShareLink("");
  };

  const shareHandler = (bouquetId, imageUrl, flowers, price) => {
    setShareLink({ bouquetId, imageUrl, flowers, price });
  };

  return (
    <div className={s.card}>
      <div className={s.card__image}>
        <LazyLoadImage
          className={`${s.card__image_img} ${scale ? s.active : ""}`}
          src={`https://app.vazaflower.com${imageUrl}`}
          onClick={() => setScale(!scale)}
          width={360}
          height={360}
          alt="flowers"
          placeholder={<CircularProgress />}
        />
      </div>
      <div className={currentCard ? s.card__info_active : s.card__info}>
        {currentCard ? (
          <>
            <h3 className={s.order_title}>Замовлення # {orderId}</h3>
            <p className={s.order_phone}>Ваш контактний телефон:</p>
            +
            <input
              type="text"
              onChange={(e) => {
                setOrderPhoneNumber(e.currentTarget.value);
              }}
              value={orderPhoneNumber}
              className={
                validationError
                  ? s.order_phone_number_error
                  : s.order_phone_number
              }
            />
            <p>Вкажіть зручний спосіб зв'язку:</p>
            <div className={s.order_contactType_wrapper}>
              <div className={s.order_contactType_box}>
                <label htmlFor="PhoneType" className={s.order_contactType}>
                  <img
                    src={
                      contactType === "Callback"
                        ? "/phone-on.svg"
                        : "/phone-off.svg"
                    }
                    alt=""
                    className={s.order_contactType_img}
                  />
                  <Typography
                    className="order_contactType_text"
                    color={contactType === "Callback" ? "#55DBFF" : "#649b99"}
                  >
                    Дзвінок
                  </Typography>
                </label>
                <input
                  id="PhoneType"
                  onChange={(e) => setContactType(e.currentTarget.value)}
                  checked={contactType === "Callback"}
                  value="Callback"
                  name="contactType"
                  type="radio"
                  className={s.order_check}
                  hidden
                />
              </div>
              <div className={s.order_contactType_box}>
                <label htmlFor="TelegamType" className={s.order_contactType}>
                  <img
                    src={
                      contactType === "Telegram"
                        ? "/telegram-on.svg"
                        : "/telegram-off.svg"
                    }
                    alt=""
                    className={s.order_contactType_img}
                  />
                  <Typography
                    className="order_contactType_text"
                    color={contactType === "Telegram" ? "#55DBFF" : "#649b99"}
                  >
                    Telegram
                  </Typography>
                </label>
                <input
                  id="TelegamType"
                  onChange={(e) => setContactType(e.currentTarget.value)}
                  checked={contactType === "Telegram"}
                  value="Telegram"
                  name="contactType"
                  type="radio"
                  className={s.order_check}
                  hidden
                />
              </div>
              <div className={s.order_contactType_box}>
                <label htmlFor="ViberType" className={s.order_contactType}>
                  <img
                    src={
                      contactType === "Viber"
                        ? "/viber-on.svg"
                        : "/viber-off.svg"
                    }
                    alt=""
                    className={s.order_contactType_img}
                  />
                  <Typography
                    className="order_contactType_text"
                    color={contactType === "Viber" ? "#55DBFF" : "#649b99"}
                  >
                    Viber{" "}
                  </Typography>
                </label>
                <input
                  id="ViberType"
                  onChange={(e) => setContactType(e.currentTarget.value)}
                  checked={contactType === "Viber"}
                  value="Viber"
                  name="contactType"
                  type="radio"
                  className={s.order_check}
                  hidden
                />
              </div>
            </div>
            <FormGroup>
              <FormControlLabel
                labelPlacement="start"
                control={
                  <Checkbox
                    checked={isDelivery}
                    onChange={(e) => {
                      setIsDelivery(e.target.checked);
                      console.log(e.target.checked);
                    }}
                  />
                }
                label="Замовити з доставкою?"
              />
            </FormGroup>
            {checkOrderPost ? (
              <Alert
                onClose={() => {
                  setCheckOrderPost(false);
                }}
                severity="success"
              >
                <AlertTitle>Дякуємо!</AlertTitle>
                Ми зв'яжемось з Вами найближчим часом
              </Alert>
            ) : null}
            {isDelivery ? (
              <div className={s.order_delivery_info}>
                <input
                  name="delivery_address"
                  value={orderDeliveryAddress}
                  onChange={(e) => {
                    setOrderDeliveryAddress(e.currentTarget.value);
                  }}
                  type="text"
                  className={s.order_delivery_address}
                  placeholder="Адреса доставки"
                />

                <input
                  name="delivery_phone"
                  value={orderDeliveryNumber}
                  onChange={(e) => {
                    setOrderDeliveryNumber(e.currentTarget.value);
                  }}
                  type="text"
                  className={s.order_delivery_phone}
                  placeholder="Телефон отримувача"
                />
              </div>
            ) : null}
            {!isSold && (
              <p className={s.order_price}>
                Ціна: <b>{currentCard.price} грн</b>
              </p>
            )}
          </>
        ) : (
          <Box>
            <p
              className={(s.card__info_flowers, isFull ? s.full : "")}
              onClick={() => setIsFull(!isFull)}
            >
              Квіти:{" "}
              <span className={s.card__flowers}>{flowers.join(", ")}</span>
            </p>
            <p
              style={{ display: "inline-block", marginRight: "30px" }}
              className={s.card__info_size}
            >
              Розмір: <span> {size} </span>
            </p>
            {!isSold && (
              <p
                style={{ display: "inline-block" }}
                className={s.card__info_price}
              >
                Ціна: <span> {price} грн </span>
              </p>
            )}
            <br />
          </Box>
        )}

        {checkOrderPost || isSold ? (
          <RWebShare
            data={{
              text: "Дивись який гарний букет квітів!",
              url: `https://vazaflower.com/gallery/${bouquetId}`,
              title: "Надіслати посилання на букет:",
            }}
            sites={[
              "telegram",
              "facebook",
              "whatsapp",
              "twitter",
              "mail",
              "copy",
            ]}
            onClick={() => console.log("copy share link")}
          >
            <Button
              onClick={shareLink ? cancelShare : shareHandler}
              className={s.shareButton}
              startIcon={<ShareIcon />}
              variant={"outlined"}
              size="large"
              sx={{
                fontSize: "14px",
                fontFamily: "Montserrat, sans-serif",
                color: "#649b99",
                padding: "2px 6px",
                marginBottom: "8px",
              }}
              color="success"
            >
              Поділитись
            </Button>
          </RWebShare>
        ) : (
          <Grid
            container
            sx={{
              margin: "5px 0 8px 0",
              justifyContent: "space-between",
              gap: "7px",
            }}
          >
            <Button
              onClick={currentCard ? orderConfirm : orderHandler}
              className={currentCard ? s.buy__button_active : s.buy__button}
              variant={currentCard ? "contained" : "outlined"}
              size="large"
              sx={{
                fontSize: "14px",
                fontFamily: "Montserrat, sans-serif",
                color: "#55DBFF",
                padding: "2px 6px",
              }}
              color="success"
              startIcon={
                currentCard ? (
                  <CheckOutlinedIcon />
                ) : (
                  <ShoppingCartOutlinedIcon />
                )
              }
            >
              {currentCard ? "підтвердити" : "замовити"}
            </Button>

            {!currentCard ? (
              <RWebShare
                data={{
                  text: "Дивись який гарний букет квітів!",
                  url: `https://vazaflower.com/shop/${bouquetId}`,
                  title: "Надіслати посилання на букет:",
                }}
                sites={[
                  "telegram",
                  "facebook",
                  "whatsapp",
                  "twitter",
                  "mail",
                  "copy",
                ]}
                onClick={() => console.log("copy share link")}
              >
                <Button
                  onClick={shareLink ? cancelShare : shareHandler}
                  className={s.shareButton}
                  startIcon={<ShareIcon />}
                  variant={"outlined"}
                  size="large"
                  sx={{
                    fontSize: "14px",
                    fontFamily: "Montserrat, sans-serif",
                    color: "#649b99",
                    padding: "2px 6px",
                  }}
                  color="success"
                >
                  Поділитись
                </Button>
              </RWebShare>
            ) : (
              <Button
                onClick={cancelHandler}
                startIcon={<CloseIcon />}
                variant={"outlined"}
                size="large"
                color="success"
                sx={{
                  fontSize: "14px",
                  fontFamily: "Montserrat, sans-serif",
                  padding: "2px 6px",
                  color: "#649b99",
                }}
              >
                скасувати
              </Button>
            )}
          </Grid>
        )}
        {isSold && (
          <Button
            onClick={() => setOpenModal(true)}
            className={s.shareButton}
            variant={"outlined"}
            size="large"
            sx={{
              fontSize: "14px",
              fontFamily: "Montserrat, sans-serif",
              color: "#649b99",
              padding: "2px 6px",
              marginBottom: "8px",
              marginLeft: "40px",
              "@media(max-width: 390px)": {
                padding: "2px 3px",
                marginLeft: "5px",
              },
            }}
            color="success"
          >
            уточнити ціну
          </Button>
        )}
        <Modal
          open={openModal}
          onClose={() => setOpenModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              border: "2px solid #000",
              borderRadius: "10px",
              boxShadow: 24,
              p: 4,
            }}
          >
            {!priceRequestSucces ? (
              <>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  color={"#649b99"}
                  sx={{ color: "#649b99", marginBottom: "10px" }}
                >
                  Вкажіть, будь-ласка, Ваш контактний номер телефону
                </Typography>
                +
                <input
                  type="text"
                  onChange={(e) => {
                    setOrderPhoneNumber(e.currentTarget.value);
                  }}
                  value={orderPhoneNumber}
                  className={
                    validationError
                      ? s.order_phone_number_error
                      : s.order_phone_number
                  }
                />
                <Button
                  onClick={priceRequest}
                  className={s.shareButton}
                  variant={"outlined"}
                  size="large"
                  sx={{
                    fontSize: "14px",
                    fontFamily: "Montserrat, sans-serif",
                    color: "#649b99",
                    padding: "2px 6px",
                    marginBottom: "8px",
                    marginLeft: "40px",
                  }}
                  color="success"
                  startIcon={<Check />}
                >
                  ОК
                </Button>
              </>
            ) : (
              <Alert severity="success">
                Ми зв'яжемось з Вами найближчим часом
              </Alert>
            )}
          </Box>
        </Modal>
      </div>
    </div>
  );
};
