import { Button } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import s from "./Navbar.module.scss";
const Navbar = () => {
  const available = "НОВІ БУКЕТИ";
  const archive = "ГАЛЕРЕЯ";

  const [title, setTitle] = useState(available);
  const [buttonText, setButtonText] = useState(archive);
  const location = useLocation();
  const [URLcheck, setURLcheck] = useState(false);
  const titleHandler = () => {
    if (title === available) {
      setTitle(archive);
      setButtonText(available);
    } else {
      setTitle(available);
      setButtonText(archive);
    }
  };

  useEffect(() => {
    setURLcheck(location.pathname.includes("feedback"));
  }, [location.pathname]);

  return !URLcheck ? (
    <div className={s.title__wrapper}>
      <h1 className={s.title}> {title} </h1>

      <Link to={title === available ? "/gallery" : "/shop"}>
        <Button
          onClick={titleHandler}
          variant="outlined"
          size="large"
          sx={{
            fontSize: "20px",
            fontFamily: "Montserrat, sans-serif",
            color: "#55DBFF",
            paddingY: "2px",
            paddingX: "8px",
            textDecoration: "none",
            "@media(max-width: 390px)": {
              padding: "2px 6px",
              fontSize: "16px",
            },
          }}
          color="success"
        >
          {buttonText}
        </Button>
      </Link>
    </div>
  ) : (
    <></>
  );
};

export default Navbar;
