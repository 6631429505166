import s from "./Header.module.scss";

export const Header = () => {
  return (
    <header
      className={s.header}
      style={{
        backgroundImage: "url(/greenhouse-interior.jpg)",
        backgroundSize: "cover",
        objectFit: "cover",
        backgroundPosition: "center -50px",
      }}
    >
      <div className={s.neon_text}>
        <h1 className={s.neon_title}>VAZA</h1>
        <h3 className={s.neon_discr}>flower shop</h3>
      </div>
    </header>
  );
};
