import {
  Box,
  Container,
  Typography,
  Rating,
  TextField,
  Alert,
  Stack,
  Button,
  FormControl,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import React, { useState } from "react";
import Footer from "../Footer";

import axios from "../axios.js";

import { Link } from "react-router-dom";
const Feedback = () => {
  const [star, setStar] = useState(3);
  const [message, setMessage] = useState("");
  const [contact, setContact] = useState("");
  const [success, setSuccess] = useState(false);

  const cleanForm = () => {
    setContact("");
    setMessage("");
    setStar(3);
  };

  const formHandler = async () => {
    try {
      const feedback = { star, message, contact };
      await axios.post("/feedback", feedback);
      cleanForm();
      setSuccess(true);

      setTimeout(() => setSuccess(false), 8000);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box
      sx={{
        background: "linear-gradient(180deg, #003441 0%, #021C24 100%)",
        margin: "0",
      }}
    >
      <Container>
        <Box component={"form"}>
          <Stack
            sx={{ width: "100%", textAlign: "center", margin: "0 auto" }}
            spacing={2}
          >
            <Typography
              variant="h3"
              sx={{
                fontWeight: "100",
                textShadow: "0 1px 2px #000",
                color: "#649b99",
              }}
            >
              Форма для звернення
            </Typography>
            <Typography
              component="legend"
              variant="h5"
              sx={{
                fontWeight: "100",
                textShadow: "0 1px 2px #000",
                color: "#649b99",
              }}
            >
              Наскільки вірогідно, що Ви порекомендуєте наш магазин своїм друзям
              та знайомим?
            </Typography>
            <Rating
              name="simple-controlled"
              value={star}
              max={10}
              onChange={(event, newValue) => {
                setStar(newValue);
              }}
              sx={{
                width: "320px",
                margin: "15px auto !important",
                fontSize: "2rem",
                color: "#649b99",
              }}
            />
            <FormControl sx={{ paddingTop: "5px" }}>
              <TextField
                id="outlined-multiline-static"
                label="Ваше звернення"
                value={message}
                onChange={(e) => setMessage(e.currentTarget.value)}
                multiline
                rows={5}
                required
              />
            </FormControl>
            <Alert severity="info" variant="outlined">
              Якщо Ви бажаєте отримати зворотній зв'язок за своїм зверненням,
              будь ласка, вкажіть Ваш контактний телефон або електронну пошту у
              полі нижче
            </Alert>
            <TextField
              id="outlined-multiline-flexible"
              label="Телефон або електронна пошта"
              value={contact}
              onChange={(e) => setContact(e.currentTarget.value)}
            />
            {success ? (
              <Alert severity="success">
                Дякуємо за зворотній зв'язок! Час розгляду звернення до 3-х днів
              </Alert>
            ) : (
              <Button
                variant="contained"
                onClick={formHandler}
                startIcon={<SendIcon />}
              >
                Надіслати
              </Button>
            )}

            <Link to="/" underline={"none"}>
              <Button
                variant="outlined"
                sx={{ textDecoration: "none", marginY: "10px" }}
              >
                Повернутись на головну
              </Button>
            </Link>
          </Stack>
        </Box>
      </Container>
      <Footer />
    </Box>
  );
};

export default Feedback;
