import axios from "./axios.js";
import { useEffect, useState } from "react";

import { Box, Button, Grid, Pagination, Typography } from "@mui/material";
import { Card } from "./Card";

import Alert from "@mui/material/Alert";
import { Container } from "@mui/system";
import { useParams } from "react-router-dom";

export const Gallery = () => {
  const [oldCards, setOldCards] = useState([]);

  const [fetching, setFetching] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  const [oneBouquet, setOneBouquet] = useState("");
  const params = useParams();

  useEffect(() => {
    if (params.id) {
      async function fetchOneBouquet() {
        try {
          const resBouquet = await axios.get(`/gallery/${params.id}`);

          setOneBouquet(resBouquet.data);
        } catch (error) {
          console.error(error);
          alert("Ошибка при загрузке букетов с сервера: " + error);
        }
      }
      fetchOneBouquet();
    }

    try {
      async function fetchGalleryBouquets() {
        if (fetching) {
          const resCards = await axios.get(
            `/gallery?page=${currentPage}&size=9`
          );
          setOldCards(resCards.data.reverse());
        }
      }

      fetchGalleryBouquets();
    } catch (error) {
      console.error(error);
      alert("Ошибка при загрузке букетов с сервера: " + error);
    } finally {
      setFetching(false);
    }
  }, [fetching, currentPage, params.id]);

  const paginationHandler = (e, page) => {
    setCurrentPage(page);
    setFetching(true);
    window.scrollTo(0, 0);
  };

  return oneBouquet ? (
    <Container>
      <Alert severity="info">
        Цей букет вже знайшов свого власника, але Ви можете надіслати нам
        посилання, натиснувши кнопку "Поділитись", та ми запропонуємо схожі
        поєднання з наявних квітів{" "}
      </Alert>
      <Grid container>
        <Grid item xs>
          {oneBouquet.map((bouquet) => {
            return (
              <Card
                key={bouquet._id}
                bouquetId={bouquet._id}
                imageUrl={bouquet.imageUrl}
                size={bouquet.size}
                price={bouquet.price}
                isSold={bouquet.isSold}
                flowers={bouquet.flowers}
                {...bouquet}
              />
            );
          })}
        </Grid>
      </Grid>
      <Box sx={{ width: "100%", textAlign: "center", margin: "15px auto" }}>
        <Button variant="outlined" href="https://vazaflower.com">
          Повернутись на головну
        </Button>
      </Box>
    </Container>
  ) : (
    <Container>
      <Alert severity="info">
        Ці букети та композиції вже знайшли своїх власників, але Ви можете
        надіслати нам посилання на букет натиснувши кнопку "Поділитись" та ми
        запропонуємо схожі поєднання з наявних квітів
      </Alert>

      <Grid container spacing={3} sx={{ marginBottom: "60px" }}>
        {oldCards.length ? (
          oldCards.map((oldCard, index) => {
            return (
              <Grid key={oldCard._id} item xs>
                <Card
                  bouquetId={oldCard._id}
                  imageUrl={oldCard.imageUrl}
                  flowers={oldCard.flowers}
                  size={oldCard.size}
                  price={oldCard.price}
                  loading={fetching}
                  {...oldCard}
                />
              </Grid>
            );
          })
        ) : (
          <Typography
            variant="h6"
            color="primary"
            sx={{ margin: "50px auto", width: "300px" }}
          >
            Стривайте, ми вже працюємо над новим контентом 😉
          </Typography>
        )}
      </Grid>
      <Grid container>
        <Pagination
          count={10}
          variant="outlined"
          page={currentPage}
          onChange={paginationHandler}
          sx={{ margin: "50px auto", textAlign: "center" }}
        />
      </Grid>
    </Container>
  );
};
