import { Link } from "react-router-dom";
import s from "./Footer.module.scss";

const Footer = () => {
  return (
    <footer className={s.footer}>
      <div className={s.footer_wrapper}>
        <div className={s.footer_container}>
          <div className={s.footer_info}>
            <div className={s.footer_box}>
              <h3 className={s.footer_info_title}>Магазини на мапі:</h3>
              <a href="https://goo.gl/maps/GFunHAGyiXFHZy6M6">
                <p className={s.footer_info_address}>
                  м.Дніпро,
                  <br />
                  вул. М.Грушевського 60/4
                </p>
              </a>
            </div>

            <div className={s.footer_box}>
              <h3 className={s.footer_box_title}>Графік роботи:</h3>
              <p className={s.footer_box_time}>
                <b>08:30 - 20:00</b> <br /> <span>без перерви та вихідних</span>
              </p>
            </div>
          </div>
          <div className={s.footer_box_links}>
            <a href="tel:+380933800849" className={s.footer_box_link}>
              <img
                className={s.footer_box_link_img}
                src="/phone-on.svg"
                alt="social-link"
              />
              <p className={s.footer_box_link_text}>+380933800849</p>
            </a>
            <a href="http://t.me/VAZA_flowershop" className={s.footer_box_link}>
              <img
                className={s.footer_box_link_img}
                src="/telegram-on.svg"
                alt="social-link"
              />
              <p className={s.footer_box_link_text}>@VAZA_flowershop</p>
            </a>
            <a
              href="https://invite.viber.com/?g=NPawlgWPkk9cwH6Ae6__dTsBL_l4c-Ek"
              className={s.footer_box_link}
            >
              <img
                className={s.footer_box_link_img}
                src="/viber-on.svg"
                alt="social-link"
              />
              <p className={s.footer_box_link_text}>+380933800849</p>
            </a>
            <a
              href="https://instagram.com/vaza.flower?igshid=YzAyZWRlMzg="
              className={s.footer_box_link}
            >
              <img
                className={s.footer_box_link_img}
                src="/instagram-on.svg"
                alt="social-link"
              />
              <p className={s.footer_box_link_text}>@vaza.flower</p>
            </a>
            <Link
              to="/feedback"
              className={s.footer_box_link}
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            >
              <img
                className={s.footer_box_link_img}
                src="/feedback.png"
                alt="social-link"
                width={"60px"}
                height={"60px"}
              />
              <p className={s.footer_box_link_text}>Оцінити</p>
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
