import { Header } from "./components/Header";
import Home from "./components/Home";
import { Shop } from "./components/Shop";
import { Gallery } from "./components/Gallery";

import { Route, Routes } from "react-router-dom";
import Feedback from "./components/Feedback";
import Navbar from "./components/Navbar";

function App() {
  return (
    <>
      <Header />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/shop/:id" element={<Shop />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/gallery/:id" element={<Gallery />} />
        <Route path="/feedback" element={<Feedback />} />
      </Routes>
    </>
  );
}

export default App;
