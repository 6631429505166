import { Navigate } from "react-router-dom";

const Home = () => {


    return (

        <Navigate to="/shop" replace={true} />
    )
}

export default Home